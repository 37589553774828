import React from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'
import CruisLogo from '../assets/logo'


const Nav = styled('div')`
  background-color: #414E83;
  background-image: linear-gradient(to right, #5669A5, #414E83);
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: 1000;

  @media (max-width: 660px) {
    padding-right: 0;
  }
`

const NavLinkList = styled('div')`
  color: rgba(255,255,255,0.7);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 15px;
  font-weight: 200;
  list-style-type: none;
  align-items: center;
  height: 100%;
  letter-spacing: 0.15em;
  margin: 0;
  padding: 0;

  @media (max-width: 660px) {
    display: none;
  }

  a {
    color: rgba(255,255,255,0.7);
    display: grid;
    align-items: center;
    justify-items: center;
    justify-self: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: border-color ease-in-out 200ms;
    border-bottom: 1px solid transparent;
    outline: none;
    width: 100%;
    height: 100%;

    &.logo {
      justify-self: left;
      padding-left: 20px;
    }

    &.menuClose {
      display: none;
    }

    &.menuOpen {
      display: none;
    }

    &:visited {
      color: rgba(255,255,255,0.7);
    }

    &[aria-current="page"] {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
      color: #fff;
      font-weight: 500;
    }

    &:hover {
      color: rgba(255, 255, 255, 1);
      border-color: #fff;
      text-decoration: none;
    }

    @media (max-width: 660px) {
      font-size: 20px;
      font-weight: 400;
      justify-self: left;

      &.menuClose {
        display: inline;
        justify-self: end;
      }

      &.menuOpen {
        display: none;
      }
    }
  }
`

export default () => (
  <Nav>
    <Link className="logo" to="/"><CruisLogo /></Link>
    <NavLinkList>
      <Link to="" className="menuClose">X</Link>
      <Link to="/"><div className="item">Home</div></Link>
      <Link to="/about"><div className="item">About</div></Link>
      <Link to="/services"><div className="item">Services</div></Link>
      <Link to="/partner"><div className="item">Partner</div></Link>
      <Link to="" className="menuOpen">...</Link>
    </NavLinkList>
  </Nav>
)
