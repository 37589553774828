import React from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'

const Footer = styled('div')`
  background-color: #fff;
  margin: 0 auto;
  padding: 80px 0;
  text-align: center;
  width: 100%;

  h4 {
    margin-bottom: .5rem;
  }

  a {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 3rem;
  }
`

export default () => (
  <Footer>
    <h2>Contact Us</h2>
    <h4>Press</h4>
    <Link to="mailto://press@cruis.io">press@cruis.io</Link>
    <h4>Partnerships</h4>
    <Link to="mailto://partners@cruis.io">partners@cruis.io</Link>
    <h4>General Information</h4>
    <Link to="mailto://info@cruis.io">info@cruis.io</Link>
  </Footer>
)
