import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'react-emotion'

const Hero = styled('div')`
  background-color: #333;
  background-size: cover;
  color: rgba(2552, 255, 255, .85);
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 40px;
  padding-top: 80px;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  height: 100vh;
  width: 100%;

  .overlay {
    background: radial-gradient(rgba(0, 0, 0, .5) 10%, rgba(0, 0, 0, 0) 65%);
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const HeroHead = styled('div')`
  align-self: end;
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 500;
`

const HeroSubhead = styled('div')`
  color: #fff;
  display: grid;
  font-family: "Montserrat";
  font-size: 18px;
  z-index: 500;
`

const HeroActions = styled('div')`
  color: rgba(255, 255, 255, 0.5);
  display: grid;
  align-self: end;
  justify-items: center;
  padding-bottom: 20px;
  z-index: 500;

  polyline {
    stroke: rgba(255, 255, 255, 0.5);
  }
`

export default props => (
  <Hero style={{ backgroundImage: `url(${props.cover})`}}>
    <div className="overlay"></div>
    <HeroHead>{props.headText}</HeroHead>
    <HeroSubhead>
      {props.subheadText}
      <HeroActions>
        scroll
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <polyline fill="none" stroke="#FFF" strokeWidth="3" points="24.728 2.728 24.728 20.728 6.728 20.728" transform="rotate(45 15.728 11.728)"/>
        </svg>
      </HeroActions>
    </HeroSubhead>
  </Hero>
)
