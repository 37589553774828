import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import styled, { css } from 'react-emotion'
import { rhythm, scale } from '../utils/typography'
import SideBar from './sidebar'
import './layout.css'
import Footer from '../components/Footer'
import favicon32 from '../assets/favicon-32x32.png'
import favicon from '../assets/favicon-16x16.png'
import appleTouchIcon from '../assets/apple-touch-icon.png'

const NavLinks = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const Container = styled('div')`
  margin: 0;
  padding: 0;
  width: 100%;
`

const sidebarMenu = css`
  @media (min-width: 661px) {
    display: none;
  }
`


const Layout = ({ children, data }) => (
  <Container>
    <Helmet
      title='Cruisio - Dramatically improving fuel efficiency through biomimetic technology'
      meta={[
        { name: 'description', content: 'sample description' },
        { name: 'keywords', content: 'tags tags tags' },
        { property: 'og:title', content: 'Cruisio' },
        { property: 'og:description', content: 'Dramatically improving fuel efficiency through biomimetic technology' },
        { property: 'og:image', content: ''},
        { property: 'og:url', content: 'http://cruis.io/index.html' },
        { property: 'twitter:title', content: 'Cruisio' },
        { property: 'twitter:description', content: 'Dramatically improving fuel efficiency through biomimetic technology' },
        { property: 'twitter:image', content: ''},
        { property: 'twitter:card', content: 'summary_large_image' }
      ]}
      link={[
        { rel: 'apple-touch-icon', sizes: '180x180', href: `${appleTouchIcon}` },
        { rel: 'shortcut icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
        { rel: 'shortcut icon', type: 'image/png', sizes: '16x16', href: `${favicon}` }
      ]}
    />
    <Nav />
    <SideBar />
    {children}
    <Footer />
  </Container>
)

export default Layout

// export const query = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `
