import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import styled, { css } from 'react-emotion'

const menu = css`
  font-size: 24px;
`

export default props => {
  return (
    <Menu right width={ '240px' } id={ "sidebar" } css={ menu }>
      <a href="/" className="menu-item">Home</a>
      <a href="/about" className="menu-item">About</a>
      <a href="/services" className="menu-item">Services</a>
      <a href="/partner" className="menu-item">Partners</a>
    </Menu>
  );
};
