import React from 'react'
import Helmet from 'react-helmet'
import { Link,graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Hero from '../components/Hero'

import Layout from '../components/layout'
import Main from '../components/Main'
import Panel from '../components/panel'
import { rhythm, scale } from '../utils/typography'

class BlogPostTemplate extends React.Component {

  render() {
    console.log(this.props)
    const { title, publishDate, author, tags, heroImage, body } = this.props.data.contentfulBlogPost
    return (
      <Layout>
        <Main>
          <Panel>
            <h1 style={{
              color: '#333',
              fontSize: '1.75rem',
              paddingTop: '3rem',
              paddingBottom: '0.5rem'
            }}>
              {title}
            </h1>
            <p style={{
              fontWeight: '600',
              marginBottom: '0',
            }}>{author.name}</p>
            <p style={{
              color: '#999',
              fontSize: '0.875rem',
            }}>{publishDate}</p>
            <p style={{
              color: '#999',
              fontSize: '0.875rem',
            }}>{tags}</p>
              <div>
                <Img style={{
                  marginBottom: '3rem',
                  maxHeight: '380px',
                }} 
                  sizes={heroImage.sizes} 
                  alt={title}
                />
              </div>
            <div style={{
              borderBottom: '1px solid #ddd',
              lineHeight: '1.8',
              paddingBottom: '2rem',
              textAlign: 'left',
            }}
              dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }} />
          </Panel>
        </Main>
      </Layout>
    )
  }
}

// BlogPost.PropTypes = {
//   data: PropTypes.object.isRequired
// }

// export default BlogPost

export default BlogPostTemplate

export const pageQuery = graphql`
    query blogPostQuery($slug: String!){
        contentfulBlogPost(slug: {eq: $slug}) {
            title
            author {
              name
            }
            tags
            publishDate(formatString: "MMMM DD, YYYY")
            heroImage {
                sizes(maxWidth: 800) {
                    ...GatsbyContentfulSizes
                }
            }
            body {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`