import Typography from 'typography'

const typography = new Typography({
  baseFontSize: "16px",
  baseLineHight: "1.6",
  headerFontFamily: [
    "Montserrat",
    "Helvetica Neue",
    "Helvetica",
    "sans-serif",
  ],
  bodyFontFamily: [
    "IBM Plex Sans",
    "Helvetical Neue",
    "serif",
  ],
  googleFonts: [
    {
      name: 'Montserrat',
      styles: [
        '200',
        '400',
        '700',
        '900',
      ],
    },
    {
      name: 'IBM Plex Sans',
      styles: [
        '200',
        '400',
        '700',
        '900',
      ],
    },
  ],
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
