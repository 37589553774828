import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'react-emotion'

const Main = styled('div')`
  margin: 0 auto;
  text-align: center;
  width: 100%;
`

  export default ({children}) => (
  <Main>
    { children }
  </Main>
)
