import React from 'react'
import styled from 'react-emotion'


const PanelContainer = styled.div`
  width: 100%;

  &:nth-child(even) {
    background-color: #F5F8FC;
  }
`

const PanelBody = styled('div')`
  margin: 0 auto;
  max-width: 600px;
  padding: 80px 1rem;

  @media (min-width: 376px) {
    padding: 80px 0;
  }

  &.alt {
    background-color: #F5F8FC;
  }

  & h1 {
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  & p {

  }

  & ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .strong {
    font-weight: 600;
  }
`

const Panel = ({ children, props }) => (
  <PanelContainer>
    <PanelBody>
      { children }
    </PanelBody>
  </PanelContainer>
)

export default Panel
